import "core-js/modules/es.array.push.js";
import { mapGetters, mapState } from 'vuex';
import axios from 'axios';
import articleService from '@/services/article/filter.js';
//import ArticleFilter from '@/views/article/ArticleFilter.vue'
import NameSearchHelpDialog from '@/components/crm/addresses/NameSearchHelpDialog.vue';
import LBAButton from '@/components/ui/buttons/LBAButton.vue';
import LBADialog from '@/components/ui/dialogs/LBADialog.vue';
import LBAQRCodeScanner from '@/components/ui/scanner/LBAQRCodeScanner.vue';
export default {
  components: {
    NameSearchHelpDialog,
    LBAButton,
    LBADialog,
    LBAQRCodeScanner
  },
  data: () => ({
    locationMenu: 'center',
    fullTextSearchUrl: '/v1/articles/search',
    fullTextSearchInput: '',
    qrModal: false,
    filterSet: false,
    searchSuccessful: false,
    results: [],
    controller: undefined,
    loading: false,
    searchStartedOnce: false,
    akey: {
      firma: null,
      artNr: null
    },
    aartNr2: null,
    aarticleDescription: null,
    aarticleType: {
      key: {
        firma: null,
        tpCode: null
      },
      description: null
    },
    aarticleCategory: {
      key: {
        firma: null,
        lfdnr: null
      },
      description: null
    },
    aarticleGroup: {
      key: {
        firma: null,
        lfdnr: null
      },
      description: null
    },
    headers: [{
      text: 'Artikelnummer',
      align: 'start',
      sortable: true,
      value: 'artNr'
    }, {
      text: 'Artikelnummer 2',
      value: 'artNr2'
    }, {
      text: 'Artikelbezeichnung',
      value: 'articleDescription'
    }, {
      text: 'Artikelbezeichnung2',
      value: 'articleDescription2'
    }],
    articlenumber: null,
    articlenumber_2: null,
    articledescription: null,
    articlecategory: null,
    articletype: null,
    articlegroup: null,
    allarticlegroupitems: [],
    allarticletypeitems: [],
    allarticlecategoryitems: [],
    filtersActiveIndex: 1
    //filterSet: false
  }),
  computed: {
    ...mapState({
      filtersActive: state => state.crmFilter.filterActivated
    }),
    ...mapGetters(['getFilter']),
    id() {
      // Berechnungslogik für "id"...
      return this.someValue + this.anotherValue;
    }
  },
  methods: {
    closeFilterDialog() {
      this.filtersActive = false;
      this.$store.dispatch('toggleFilterActivated');
    },
    handleScanned(res) {
      this.qrModal = false;
      this.fullTextSearchInput = res;
      this.searchTrigger();
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true;
      }
      return false;
    },
    initiateSearchUI() {
      this.loading = true;
      this.results = [];
    },
    searchFullText(query) {
      if (query == undefined) return;
      this.initiateSearchUI();
      //Check if there are any previous pending requests
      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.');
      }
      this.controller = new AbortController();
      axios.get(this.fullTextSearchUrl, {
        params: {
          q: query
        },
        signal: this.controller.signal
      }).then(response => {
        const array = [];
        for (var i in response.data) {
          array.push([i, response.data[i]]);
        }
        this.results = response.data;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error searching article: ' + err);
      });
    },
    searchTrigger() {
      if (this.filtersActive) {
        this.searchStartedOnce = true;
        this.removeQueryParam();
        this.searchArticlesFiltered();
        this.searchFullText(this.fullTextSearchInput);
      }
      //Volltextsuche
      else {
        if (this.fullTextSearchInput != null && this.fullTextSearchInput != '' && String(this.fullTextSearchInput).length > 2) {
          this.searchStartedOnce = true;
          this.$router.push({
            query: {
              q: this.fullTextSearchInput
            }
          });
          this.searchFullText(this.fullTextSearchInput);
        } else {
          this.removeQueryParam();
        }
      }
    },
    setResults(value) {
      this.results = value;
    },
    removeQueryParam() {
      this.$router.replace({
        query: {}
      }).catch(() => {});
    },
    openArticle(articleNumber) {
      this.$store.dispatch('setSaveFilter', {
        articlenumber: this.articleNumber,
        articlenumber_2: this.articleNumber_2,
        articledescription: this.articleDescription,
        articletype: this.articleType,
        articlecategory: this.articleCategory,
        articlegroup: this.articleGroup
      });
      this.$router.push({
        name: 'Artikeldetails',
        params: {
          articleNumber: articleNumber
        }
      });
    },
    openArticleDataTable(value) {
      this.openArticle(value.artNr);
      this.$store.state.articleDetails.articleExists = true;
    },
    articleType(item) {
      return articleService.getArticleTypeDetailed(item.articleTypes[0]);
    },
    scrollToTop() {
      document.getElementById('app').scrollIntoView({
        behavior: 'smooth'
      });
    },
    scrollToResults() {
      const element = this.$refs['resultsTable'];
      if (element) {
        this.$nextTick(() => {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    },
    twoCharactersValidation(value) {
      return this.filtersActive || !!value && String(value).length > 2 || 'Bitte geben Sie mehr als zwei Zeichen ein';
    },
    toggleFilter() {
      this.$store.dispatch('toggleFilterActivated');
      if (this.filtersActive == true) {
        this.fullTextSearchInput = '';
      }
      this.$emit('toggle');
    },
    clearFilter() {
      this.articlenumber = null;
      this.articlenumber_2 = null;
      this.articledescription = null;
      this.articlecategory = null;
      this.articletype = null;
      this.articlegroup = null;
      this.results = [];
    },
    isFilterSet() {
      if (this.articlenumber != null || this.articlenumber_2 != null || this.articledescription != null || this.articlecategory != null || this.articletype != null || this.articlegroup != null) {
        this.filterSet = true;
      } else {
        this.filterSet = false;
      }
    },
    resetFilter() {
      this.clearFilter();
      this.$store.dispatch('resetFilter');
    },
    init_filter() {
      axios.get('/v1/articles/types', {}).then(response => {
        const array = [];
        response.data.forEach(elem => {
          array.push(elem);
        });
        this.allarticletypeitems = array;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error loading in filter' + err);
      });
    },
    /*searchTrigger() {
      if (this.filterActive) {
        this.searchStartedOnce = true
        this.removeQueryParam()
        this.searchArticlesFiltered()
      }
    },*/
    loadData() {
      this.$store.dispatch('loadArticleTypes');
      this.$store.dispatch('loadArticleCategories');
      this.$store.dispatch('loadArticleGroups');
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFilter;
      this.articlenumber = storedFilter.articlenumber;
      this.articlenumber_2 = storedFilter.articlenumber2;
      this.articledescription = storedFilter.articledescription;
      this.articletype = storedFilter.articletype;
      this.articlecategory = storedFilter.articlecategory;
      this.articlegroup = storedFilter.articlegroup;
      this.filtersActiveIndex = this.filtersActive ? 0 : 1;
    },
    searchArticlesFiltered() {
      this.akey.artNr = this.articlenumber;
      this.aartNr2 = this.articlenumber_2;
      this.aarticleDescription = this.articledescription;
      if (this.articletype != '' && this.articletype != null) this.aarticleType = this.articletype;else if (this.articletype == null || this.articletype == '') {
        this.aarticleType = {
          key: {
            firma: null,
            tpCode: null
          },
          description: null
        };
      }
      if (this.articlecategory != '' && this.articlecategory != null) this.aarticleCategory = this.articlecategory;else if (this.articlecategory == null || this.articlecategory == '') {
        this.aarticleCategory = {
          key: {
            firma: null,
            lfdnr: null
          },
          description: null
        };
      }
      if (this.articlegroup != '' && this.articlegroup != null) this.aarticleGroup = this.articlegroup;else if (this.articlegroup == null || this.articlegroup == '') {
        this.aarticleGroup = {
          key: {
            firma: null,
            lfdnr: null
          },
          description: null
        };
      }
      this.filterArticle(this.akey, this.aartNr2, this.aarticleDescription, this.aarticleType, this.aarticleCategory, this.aarticleGroup);
    },
    filterArticle(akey, aartNr2, aarticleDescription, aarticleType, aarticleCategory, aarticleGroup) {
      this.initiateSearchUI();
      console.warn(akey);
      articleService.filterArticle(akey, aartNr2, aarticleDescription, aarticleType, aarticleCategory, aarticleGroup).then(data => {
        this.results = data;
        this.loading = false;
      }).catch(err => {
        console.log('error searching article: ' + err);
        this.loading = false;
      });
    }
  },
  watch: {
    articletype(newValue) {
      if (newValue === null) {
        this.articlecategory = null;
        this.articlegroup = null;
        return;
      }
      axios.post('/v1/articlesFilteredDependencies/dependenciesCategories', newValue).then(response => {
        const array = [];
        response.data.forEach(elem => {
          array.push({
            description: elem.description,
            id: elem.key.lfdnr
          });
        });
        this.allarticlecategoryitems = array;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error loading in filter' + err);
      });
      axios.post('/v1/articlesFilteredDependencies/dependenciesGroups', newValue).then(response => {
        const array = [];
        response.data.forEach(elem => {
          array.push({
            description: elem.description,
            id: elem.key.lfdnr
          });
        });
        this.allarticlegroupitems = array;
        this.searchSuccessful = true;
        this.loading = false;
      }).catch(err => {
        console.log('error loading in filter' + err);
      });
    },
    // Workaround for clearing validation when opening filter
    filtersActive() {
      if (this.filtersActive) {
        this.fullTextSearchInput = ' ';
        this.$nextTick(() => {
          this.fullTextSearchInput = '';
        });
      }
    }
  },
  created() {
    window.addEventListener('keydown', event => {
      if (event.key == 'Enter') {
        this.searchTrigger();
        this.closeFilterDialog();
      }
    });
  },
  mounted() {
    if (this.$route.query['q'] != null) {
      this.fullTextSearchInput = this.$route.query['q'];
      this.$store.dispatch('unsetFilterActive');
      this.searchTrigger();
    }
    this.filtersActiveIndex = this.filtersActive ? 0 : 1;
    this.init_filter();
  }
};